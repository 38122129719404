import React, { Component, Suspense, useState, useEffect } from "react";

import { Link } from "gatsby";
import SEO from "../components/Seo";

//import HomeCanvas from "../components/HomeCanvas";

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" /> 
      <main>
        
      </main>
    </>
  )
}

export default IndexPage
